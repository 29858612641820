import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_side_bar = _resolveComponent("side-bar")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_side_bar, {
      "menu-items": _ctx.sideBarManager.getSideMenuNavItems()
    }, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    }, 8, ["menu-items"])
  ]))
}